import {safeEscapeValueList} from './helpers.values.ts';
import {filterItemToString} from './helpers.filterUtils.ts';
import {isNumbersInList} from './helpers.values.ts'

export default class ExistsObject{
    viewName:string;
   
    private _targetColumn:string;
    private _column:string;
    private _display:string;
    private _bindT1:string;
    private _bindT2:string;
    private _operator:string = "inlist";
    private _columnType:string = "string";
    private _targetColumnType:string = "string";


   /* get targetColumn(){
        return this._existsTargetColumn??this._targetColumn;
    }*/
/*
    get column(){
        return this._existsColumn??this._column;
    }
*/
    

 //   get whereClause(){

   // }

    get targetColumn(){
        return this._targetColumn;
    }
    get targetColumnType(){
        return this._targetColumnType;
    }
    get column(){
        return this._column;
    }

    get display(){
        return this._display;
    }

    get binding(){
        return `T2.[${this._bindT2}] = T1.[${this._bindT1}]`
    }

    get bindingFordistinct(){
        return `T2.[${this._bindT1}] = T1.[${this._bindT2}]`

    }

    set operator(pOperator:string){
        this._operator = pOperator;
    }

    get operator(){
        if(this._operator == "="){
            return 'equals'
        }

        return this._operator;
    }

    constructor(pColumn:string, pOptions:any){
        this._column = pColumn;
        //this.binding = pOptions.binding;
        this.viewName = pOptions.viewName;
        this._targetColumn = pOptions.targetColumn??pColumn;
        this._column = pOptions.column;
        this._display = pOptions.displayColumn??this._column;
        this._bindT1 = pOptions.bindT1??this._targetColumn;
        this._bindT2 = pOptions.bindT2??this._column;
        this._columnType = pOptions.columnType??this.column.endsWith("_ID")?"number":"string";
        this._targetColumnType = pOptions.columnType??this.column.endsWith("_ID")?"number":"string";
        if(pOptions.operator) this._operator = pOptions.operator;
       // this._existsTargetColumn = pOptions._existsTargetColumn;

    }

    clone() {
        return new ExistsObject(this._column, {
            viewName: this.viewName,
            column: this._column,
            display: this._display,
            bindT1: this._bindT1,
            bindT2: this._bindT2,
            operator: this._operator,
        });
    }

    getExistsClause(pValue: any) {
        if (pValue?.constructor == Array) {
            if(!isNumbersInList(pValue)){
                 return `${this.viewName},${this.binding},[${this._display}] IN(${safeEscapeValueList(pValue, ", ")})`;
            }
            return `${this.viewName},${this.binding},[${this._column}] IN(${safeEscapeValueList(pValue, ", ")})`;
        }
   
        if (this.operator == 'not_exists_clause' || this.operator == "isblank") {
            
            return `${this.viewName},${this.binding}`;
        }
        let vColumn = this.column;
        let vType = this._columnType;
        if(this.display && ["contains","notcontains","equals","notequals",'beginswith','endswith','notendswith'].indexOf(this.operator) > -1){
            vColumn = this.display;
            vType = "string";
        }
        const vFilter = filterItemToString({
            column: vColumn,
            type: "expression",
            operator: this.operator,
            value: pValue,
            valueType:vType
        });
        //return `${this.viewName},${this.binding},[${this._column}] ${this._operator} '${safeEscapeValue(pValue)}'`;
        return `${this.viewName},${this.binding},${vFilter}`;
    }

    isFunctionItem(pObject:any){
        if(pObject.items[0].name !== this.viewName){
            return false;
        }

       // if(pObject.items[2] && pObject.items[2].column !== this.column){
         //   return false;
      //  }
       
        if(pObject.items[1].column.replace("T2.","") != this._bindT2){
            return false;
        }
        if(pObject.items[1].value.name.replace("T1.","") != this._bindT1){
            return false;
        }


        return true;
    }
}

/*
  binding:"T1.UpdatedBy_ID == T2.ID",
    viewName:"sviw_System_Persons",
    targetColumn:"UpdatedBy_ID",
    existsTargetColumn:"ID",
    existsColumn:"Name"
*/